import userCan from "@/helpers/userCan";
import { AccountCapabilityCanDeleteThings, AccountCapabilityCanDuplicateProperties } from "@/types";

export default {
  computed: {
    user() {
      return this.$store.state.global.user;
    },
    canDeleteThings() {
      if (!this.user.capabilities) {
        return false;
      }
      return userCan(this.user.capabilities, [AccountCapabilityCanDeleteThings]);
    },
    canDuplicateProperties() {
      if (!this.user.capabilities) {
        return false;
      }
      return userCan(this.user.capabilities, [AccountCapabilityCanDuplicateProperties]);
    },
  },
};
