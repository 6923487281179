<template>
  <div style="width: 100%">
    <Header
      @delete="toggleConfirm('delete')"
      @duplicate="toggleConfirm('duplicate')"
      @print="openPrinter()"
      :details="{
        title: $t('properties.properties'),
        back: { name: 'properties' },
        actions: headerActions,
      }"
    />
    <div class="sub-nav d-flex justify-content-between">
      <span class="sub-nav-button">Property creation</span>
      <div class="d-flex justify-content-end">
        <span>Property activated</span>
        <ToggleButton v-model="form.is_activated" />
      </div>
    </div>
    <div class="d-flex">
      <div class="col-8 left-side">
        <header class="lang d-flex justify-content-end">
          <Select class="lang-select" v-model="currentLanguage" :options="$store.state.global.availableLanguages" />
        </header>
        <div class="box-container">
          <template v-for="(lang, index) in $store.state.global.availableLanguages">
            <template v-if="lang.value === currentLanguage">
              <div class="input-box" :key="'title-' + index">
                <input
                  name="title"
                  type="text"
                  :placeholder="$t('properties.title.placeholder')"
                  v-model="translations[index].title"
                />
              </div>
              <div class="input-box" :key="'descr-' + index">
                <Textarea
                  name="description"
                  textareaClass="editor"
                  is-counter="true"
                  max="2000"
                  v-model="translations[index].description"
                />
              </div>
            </template>
          </template>
        </div>
        <div class="d-flex card-line">
          <div class="element">
            <span class="label">{{ $t("properties.type.title") }}</span>
            <Select
              v-model="form.type"
              :options="[
                { name: $t('overall.not_specified'), value: null },
                { name: $t('properties.type.flat'), value: 'flat' },
                { name: $t('properties.type.villa'), value: 'villa' },
                { name: $t('properties.type.house'), value: 'house' },
                { name: $t('properties.type.penthouse'), value: 'penthouse' },
                { name: $t('properties.type.office'), value: 'office' },
                { name: $t('properties.type.land'), value: 'field' },
                { name: $t('properties.type.bungalow'), value: 'bungalow' },
                { name: $t('properties.type.duplex'), value: 'duplex' },
                { name: $t('properties.type.garage'), value: 'garage' },
                { name: $t('properties.type.building'), value: 'building' },
                { name: $t('properties.type.professional_office'), value: 'business_local' },
                { name: $t('properties.type.industrial_land'), value: 'industrial_land' },
                { name: $t('properties.type.agricultural_land'), value: 'agricultural_land' },
              ]"
            />
          </div>
          <div class="element">
            <span class="label">{{ $t("properties.status.title") }}</span>
            <Select
              v-model="form.status"
              :options="[
                { name: $t('overall.not_specified'), value: null },
                { name: $t('properties.status.sell'), value: 'sell' },
                { name: $t('properties.status.rent'), value: 'rent' },
                { name: $t('properties.status.sold'), value: 'sold' },
                { name: $t('properties.status.booked'), value: 'booked' },
                { name: $t('properties.status.stock_in_trade'), value: 'stock_in_trade' },
              ]"
            />
          </div>
          <div class="element">
            <span class="label">{{ $t("properties.availability") }}</span>
            <Datepicker v-model="form.availability" :placeholder="$t('overall.add_date')" />
          </div>
          <div class="element">
            <span class="label">{{ $t("properties.price") }}</span>
            <div class="input-wrapper">
              <Input type="text" placeholder="100 000" v-model="form.price" />
              <span class="unit">{{ $store.getters.getCurrency }}</span>
            </div>
          </div>
        </div>
        <div class="panel">
          <header class="tabs">
            <ul>
              <li>
                <button @click="changeTab('specs')" :class="{ active: tab === 'specs' }">
                  {{ $t("properties.labels.specifications") }}
                </button>
              </li>
              <li>
                <button @click="changeTab('options')" :class="{ active: tab === 'options' }">
                  {{ $t("properties.labels.options") }}
                </button>
              </li>
              <li>
                <button @click="changeTab('platforms')" class="" :class="{ active: tab === 'platforms' }">
                  {{ $t("properties.labels.platforms") }}
                </button>
              </li>
            </ul>
          </header>
          <main class="box-container">
            <div class="panel-content" v-show="tab === 'specs'">
              <h1>{{ $t("properties.main_specs") }}</h1>
              <div class="box">
                <label>{{ $t("properties.construction.title") }}</label>
                <Select
                  v-model="form.construction"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: 'New', value: 'new' },
                    { name: 'Used', value: 'used' },
                    { name: 'Refurbished', value: 'refurbished' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.construction.built_in") }}</label>
                <Select
                  v-model="form.built_in"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    ...generateSelectDate(todayDate.getFullYear() + 5, todayDate.getFullYear() - 200),
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.floor.title") }}</label>
                <Select
                  v-model="form.floor"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('properties.floor.ground_floor'), value: 0 },
                    { name: '1', value: 1 },
                    { name: '2', value: 2 },
                    { name: '3', value: 3 },
                    { name: '4', value: 4 },
                    { name: '5', value: 5 },
                    { name: '6', value: 6 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.bedrooms") }}</label>
                <Select
                  v-model="form.bedrooms"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: '1', value: 1 },
                    { name: '2', value: 2 },
                    { name: '3', value: 3 },
                    { name: '4', value: 4 },
                    { name: '5', value: 5 },
                    { name: '6', value: 6 },
                    { name: '7', value: 7 },
                    { name: '8', value: 8 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.bathrooms") }}</label>
                <Select
                  v-model="form.bathrooms"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: '1', value: 1 },
                    { name: '2', value: 2 },
                    { name: '3', value: 3 },
                    { name: '4', value: 4 },
                    { name: '5', value: 5 },
                    { name: '6', value: 6 },
                    { name: '7', value: 7 },
                    { name: '8', value: 8 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.land_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.land_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.indoor_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.indoor_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.total_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.total_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.charges") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.charges" />
                  <span class="unit">/month</span>
                </div>
              </div>
              <h1>{{ $t("properties.second_specs") }}</h1>
              <div class="box">
                <label>{{ $t("properties.heating") }}</label>
                <MultiSelect
                  :placeholder="$t('overall.not_specified')"
                  v-model="form.heating"
                  :options="[
                    { name: $t('properties.fuel_oil'), value: 'fuel_oil' },
                    { name: $t('properties.wood_stove'), value: 'wood_stove' },
                    { name: $t('properties.pellet_stove'), value: 'pellet_stove' },
                    { name: $t('properties.electric'), value: 'electric' },
                    { name: $t('properties.gaz'), value: 'gaz' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.fireplace") }}</label>
                <Select
                  v-model="form.fireplace"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box" v-if="form.heating.includes('electric') || form.heating.includes('fuel_oil')">
                <label>{{ $t("properties.underfloor_heating") }}</label>
                <Select
                  v-model="form.underfloor_heating"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.energy_performance") }}</label>
                <Select
                  v-model="form.energy_performance"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.pending'), value: 'pending' },
                    { name: 'A', value: 'A' },
                    { name: 'B', value: 'B' },
                    { name: 'C', value: 'C' },
                    { name: 'D', value: 'D' },
                    { name: 'E', value: 'E' },
                    { name: 'F', value: 'F' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.car_park.title") }}</label>
                <Select
                  v-model="form.car_park"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('properties.car_park.garage'), value: 'garage' },
                    { name: $t('overall.public'), value: 'public' },
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.terrace") }}</label>
                <Select
                  v-model="form.terrace"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.garden") }}</label>
                <Select
                  :placeholder="$t('properties.garden')"
                  v-model="form.garden"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.community'), value: 'community' },
                    { name: $t('overall.no'), value: 'no', unique: true },
                  ]"
                />
              </div>
              <div class="box" v-if="form.terrace">
                <label>{{ $t("properties.terrace_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.terrace_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.patio") }}</label>
                <Select
                  v-model="form.patio"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box" v-if="form.patio">
                <label>{{ $t("properties.patio_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.patio_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.cellar") }}</label>
                <Select
                  v-model="form.cellar"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 'yes' },
                    { name: $t('overall.optional'), value: 'optional' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="box" v-if="form.cellar !== 'no'">
                <label>{{ $t("properties.cellar_area") }}</label>
                <div class="input-wrapper">
                  <input type="number" v-model="form.cellar_area" />
                  <span class="unit">m<sup>2</sup></span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.furniture") }}</label>
                <Select
                  v-model="form.furniture"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 'yes' },
                    { name: $t('overall.optional'), value: 'optional' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="hide">
                <h1>{{ $t("overall.others") }}</h1>
                <div class="box">
                  <label>{{ $t("properties.associated_key") }}</label>
                  <div class="input-wrapper">
                    <input type="text" v-model="form.key_name" :placeholder="$t('properties.key_name')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-content" v-show="tab === 'options'">
              <h1>{{ $t("overall.location") }}</h1>
              <div class="box">
                <label>{{ $t("properties.golf") }}</label>
                <Select
                  v-model="form.golf"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('properties.first_line'), value: 'first_line' },
                    { name: $t('overall.yes'), value: 'yes' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.golf_distance") }}</label>
                <div class="input-wrapper">
                  <input type="text" v-model="form.golf_distance" />
                  <span class="unit">m</span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.shops_distance") }}</label>
                <div class="input-wrapper">
                  <input type="text" v-model="form.shops_distance" />
                  <span class="unit">m</span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.beach_distance") }}</label>
                <div class="input-wrapper">
                  <input type="text" v-model="form.beach_distance" />
                  <span class="unit">m</span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.airport_distance") }}</label>
                <div class="input-wrapper">
                  <input type="text" v-model="form.airport_distance" />
                  <span class="unit">km</span>
                </div>
              </div>
              <div class="box">
                <label>{{ $t("properties.sea_sight") }}</label>
                <Select
                  v-model="form.sea_sight"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('properties.first_line'), value: 'first_line' },
                    { name: $t('overall.yes'), value: 'yes' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.sun_orientation") }}</label>
                <Select
                  v-model="form.sun_orientation"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.east'), value: 'east' },
                    { name: $t('overall.west'), value: 'west' },
                    { name: $t('overall.north'), value: 'north' },
                    { name: $t('overall.south'), value: 'south' },
                  ]"
                />
              </div>
              <h1>{{ $t("properties.accommodation") }}</h1>
              <div class="box">
                <label>{{ $t("properties.swimming_pool.title") }}</label>
                <Select
                  v-model="form.swimming_pool"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.community'), value: 'community' },
                    { name: $t('overall.no'), value: 'no' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.air_conditioning") }}</label>
                <Select
                  v-model="form.air_conditioning"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 'yes' },
                    { name: $t('overall.no'), value: 'no' },
                    { name: $t('properties.pre_installed'), value: 'pre_installed' },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.jacuzzi") }}</label>
                <MultiSelect
                  :placeholder="$t('overall.not_specified')"
                  v-model="form.jacuzzi"
                  :options="[
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.community'), value: 'community' },
                    { name: $t('overall.none'), value: 'no', unique: true },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.elevator") }}</label>
                <Select
                  v-model="form.elevator"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.dressing") }}</label>
                <Select
                  v-model="form.dressing"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.wifi") }}</label>
                <Select
                  v-model="form.wifi"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.alarm") }}</label>
                <Select
                  v-model="form.alarm"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.fitness") }}</label>
                <Select
                  v-model="form.fitness"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.solarium") }}</label>
                <MultiSelect
                  :placeholder="$t('overall.not_specified')"
                  v-model="form.solarium"
                  :options="[
                    { name: $t('overall.community'), value: 'community' },
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.no'), value: 'no', unique: true },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.sauna") }}</label>
                <MultiSelect
                  :placeholder="$t('overall.not_specified')"
                  v-model="form.sauna"
                  :options="[
                    { name: $t('overall.community'), value: 'community' },
                    { name: $t('overall.private'), value: 'private' },
                    { name: $t('overall.no'), value: 'no', unique: true },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.disabled_access") }}</label>
                <Select
                  v-model="form.disabled_access"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <div class="box">
                <label>{{ $t("properties.spa") }}</label>
                <Select
                  v-model="form.spa"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
              <h1>{{ $t("properties.commodities") }}</h1>
              <div class="box">
                <label>{{ $t("properties.television") }}</label>
                <Select
                  v-model="form.television"
                  :options="[
                    { name: $t('overall.not_specified'), value: null },
                    { name: $t('overall.yes'), value: 1 },
                    { name: $t('overall.no'), value: 0 },
                  ]"
                />
              </div>
            </div>
            <div class="panel-content platforms" v-if="tab === 'platforms'">
              <div style="width: 100%; display: flex; justify-content: space-between">
                <h1 style="flex-basis: 50%; display: inline-block">{{ $t("properties.platforms") }}</h1>
                <button class="cta disabled">{{ $t("overall.select_all") }}</button>
              </div>
              <div v-if="!form.is_activated" class="warning flex">
                <WarningSVG />{{ $t("properties.warning_property_deactivated") }}
              </div>
              <div class="country">
                <h2>{{ $t("overall.spain") }}</h2>
                <div class="flex" width="100%">
                  <div class="box">
                    <label>Kyero</label>
                    <ToggleButton v-model="platforms.kyero.es" />
                  </div>
                  <div class="box hide">
                    <label>Spain Houses</label>
                    <ToggleButton v-model="platforms.spainhouses.es" />
                  </div>
                  <div class="box hide">
                    <label>Idealista.es</label>
                    <ToggleButton v-model="platforms.idealista.es" />
                  </div>
                </div>
              </div>
              <div class="country disabled hide">
                <h2>{{ $t("overall.portugal") }}</h2>
                <div class="box">
                  <label>Idealista.pt</label>
                  <ToggleButton v-model="platforms.idealista.pt" />
                </div>
              </div>
              <div class="country">
                <h2>{{ $t("overall.belgium") }}</h2>
                <div class="box">
                  <label>Immoweb</label>
                  <ToggleButton v-model="platforms.immoweb.fr" />
                </div>
              </div>
              <div class="country disabled hide">
                <h2>{{ $t("overall.luxembourg") }}</h2>
                <div class="box">
                  <label>Immotop</label>
                  <ToggleButton v-model="platforms.immotop.fr" />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="col-4 right-side">
        <div class="box box-container box-element">
          <div class="add-thumbnail change-image" v-if="!thumbnail" @click="togglePopup">
            <span class="d-flex align-items-center justify-content-center"
              ><em><thumbnailSVG />{{ $t("properties.library.add_picture") }}</em></span
            >
          </div>
          <img :src="thumbnail" alt="Thumbnail" v-else @click="togglePopup" />
          <div class="change-image-label">
            <button class="cta">{{ $t("properties.library.title") }}</button>
          </div>

          <div class="other-specs side-infos">
            <div class="d-flex justify-content-between align-items-center">
              <label class="d-flex label-svg reference-svg"><ReferenceSVG />Reference</label>
              <div>{{ form.reference ? "#" + form.reference : "To generate" }}</div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <label class="d-flex label-svg coast-svg"><CoastSVG />Coast</label>
              <Select
                v-model="form.costa"
                :options="[
                  { name: $t('overall.not_specified'), value: null },
                  { name: 'Costa Blanca South', value: 'south' },
                  { name: 'Costa Blanca North', value: 'north' },
                  { name: 'Costa Calida', value: 'calida' },
                  { name: 'Costa Almeria', value: 'almeria' },
                  { name: 'Costa Del Sol', value: 'delsol' },
                ]"
              />
            </div>
            <div class="d-flex justify-content-between">
              <label class="d-flex label-svg project-svg"><ProjectSVG />Project</label>
              <p v-if="!phases.length">{{ $t("properties.no_project") }}</p>
              <div v-else>
                <p v-for="phase in phases" :key="phase.id">{{ phase.project.name }} - {{ phase.name }}</p>
              </div>
            </div>
            <div>
              <label class="d-flex label-svg tag-svg"><TagSVG />tags</label>
              <div class="tags flex">
                <Tag v-for="tag in tags" :key="tag.name" v-model="tag.active">{{ tag.name }}</Tag>
              </div>
            </div>
          </div>
        </div>
        <div class="other-specs box-container">
          <h2>{{ $t("overall.address") }}</h2>
          <AddressAutocomplete v-model="address" />
        </div>

        <div class="other-specs box-container partner">
          <h2>{{ $t("properties.partner") }}</h2>
          <div>
            <label for="partner_first_name">{{ $t("overall.first_name") }}</label>
            <input id="partner_first_name" type="text" v-model="partner.first_name" />
          </div>
          <div>
            <label for="partner_last_name">{{ $t("overall.last_name") }}</label>
            <input id="partner_last_name" type="text" v-model="partner.last_name" />
          </div>
          <div>
            <label for="partner_company">{{ $t("overall.company") }}</label>
            <input id="partner_company" type="text" v-model="partner.company" />
          </div>
          <div>
            <label for="partner_email">{{ $t("overall.email") }}</label>
            <input id="partner_email" type="text" v-model="partner.email" />
          </div>
          <div>
            <label for="partner_phone">{{ $t("overall.phone") }}</label>
            <input id="partner_phone" type="text" v-model="partner.phone" />
          </div>
        </div>
      </div>
      <div class="actions-bar">
        <template v-if="!loading">
          <button class="cancel">{{ $t("overall.cancel") }}</button>
          <button class="cta save fixed-button" @click="save">
            <span class="txt">{{ $t("overall.save") }}</span>
          </button>
        </template>
        <span class="loader" v-else></span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import modalBus from "@/bus/modal-bus";
import notifBus from "@/bus/notification-bus";

import WarningSVG from "@/assets/img/warning.svg";
import ReferenceSVG from "@/assets/img/reference.svg";
import CoastSVG from "@/assets/img/coast.svg";
import ProjectSVG from "@/assets/img/rocket.svg";
import TagSVG from "@/assets/img/tag.svg";
import mixWithCanDeleteThings from "@/mixins/withCanDeleteThings";

export default Vue.extend({
  name: "EditProperties",
  components: {
    WarningSVG,
    ReferenceSVG,
    CoastSVG,
    ProjectSVG,
    TagSVG,
  },
  mixins: [mixWithCanDeleteThings],
  computed: {
    headerActions() {
      let actions = {};
      if (this.canDeleteThings) {
        actions.delete = true;
        actions.print = true;
      }
      if (this.canDuplicateProperties) {
        actions.duplicate = true;
      }
      return this.$route.params.token && Object.keys(actions).length ? actions : false;
    },
    thumbnail() {
      if (this.images.ordered.length > 0) {
        return this.images.ordered[0].replace("150x150", "600x350");
      }
      return null;
    },
  },
  data() {
    return {
      platforms: {
        kyero: {
          es: false,
        },
        idealista: {
          es: false,
          pt: false,
        },
        spainhouses: {
          es: false,
        },
        immoweb: {
          fr: false,
        },
        immotop: {
          fr: false,
        },
      },
      tab: "specs",
      form: {
        id: null,
        type: null,
        is_activated: false,
        partner: null,
        status: null,
        garden: null,
        reference: null,
        availability: null,
        price: null,
        construction: null,
        swimming_pool: null,
        furniture: null,
        bedrooms: null,
        bathrooms: null,
        floor: null,
        land_area: null,
        built_area: null,
        indoor_area: null,
        total_area: null,
        charges: null,
        energy_performance: null,
        car_park: null,
        terrace: null,
        terrace_area: null,
        patio: null,
        patio_area: null,
        sea_sight: null,
        wifi: null,
        heating: [],
        underfloor_heating: null,
        fireplace: null,
        spa: null,
        fitness: null,
        elevator: null,
        air_conditioning: null,
        animals: null,
        sun_orientation: null,
        golf: null,
        jacuzzi: [],
        solarium: [],
        sauna: [],
        address: null,
        cellar: null,
        costa: null,
        cellar_area: null,
        airport_distance: null,
        shops_distance: null,
        beach_distance: null,
        disabled_access: null,
        built_in: null,
        key_name: null,
      },
      tags: [],
      address: {
        id: null,
        country: null,
        region: null,
        province: null,
        postcode: null,
        zone: null,
        city: null,
        street: null,
        number: null,
        latitude: null,
        longitude: null,
      },
      partner: {
        first_name: null,
        last_name: null,
        company: null,
        email: null,
        phone: null,
      },
      search_address: "",
      images: {
        add: [],
        ordered: [],
        delete: [],
        current: [],
      },
      phases: [],
      videos: [],
      translations: [],
      loading: false,
      currentLanguage: this.$cookies.get("usr_lang"),
    };
  },
  methods: {
    changeTab(tab = "specs") {
      this.tab = tab;
    },
    openPrinter() {
      window.open("https://api.uniqestates.be/api/properties/print/" + this.form.token, "_blank");
    },
    toggleConfirm(which) {
      let functionToCall = which === "delete" ? this.deleteMethod : this.duplicate;
      let message = which === "delete" ? "Want to DELETE this property ?" : "Want to DUPLICATE this property ?";
      modalBus.$emit("set-size", {
        width: "400px",
        "margin-top": "50px",
      });
      modalBus.$emit("change", {
        component: "Prompt",
        data: {
          methodToCall: functionToCall,
          message,
        },
      });
      modalBus.$emit("toggle");
    },
    togglePopup() {
      modalBus.$emit("change", {
        component: "Library",
        data: {
          images: this.images,
          videos: this.videos,
        },
      });
      modalBus.$emit("toggle");
    },
    deleteMethod() {
      this.loading = true;
      this.$axios
        .delete("/api/properties/" + this.form.token)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "properties" });
          notifBus.$emit("show", {
            status: "success",
            message: "Property deleted",
          });
        })
        .catch((res) => console.log(res));
    },
    print() {
      this.$axios
        .post("/api/properties/print", {
          data: {
            images: this.images,
            ...this.form,
          },
        })
        .then((res) => {
          window.open(this._global.rServer + res.data);
        });
    },
    duplicate() {
      this.dropdownActions = false;
      this.loading = true;
      this.$axios
        .get("/api/properties/duplicate/" + this.$route.params.token)
        .then(() => {
          this.$router.push({ name: "properties" });
          notifBus.$emit("show", {
            status: "success",
            message: "Property duplicated",
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    save() {
      let tags = this.tags.filter((el) => el.active).map((el) => el.value);
      this.loading = true;
      this.$axios
        .post("/api/properties", {
          form: this.form,
          translations: this.translations,
          address: this.address,
          images: this.images,
          videos: this.videos,
          partner: this.partner,
          platforms: this.platforms,
          tags,
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          const message = this.form.id
            ? this.$t("properties.notification.updated")
            : this.$t("properties.notification.created");
          notifBus.$emit("show", {
            status: "success",
            message,
          });
          this.$router.push({ name: "properties" });
        })
        .catch((res) => {
          this.loading = false;
          this.genericError();
        });
    },
  },
  watch: {
    "$store.state.global.availableLanguages": {
      immediate: true,
      handler(el) {
        this.translations = this._global.availableLanguages.map((el) => {
          return {
            lang: el.value,
            title: "",
            description: "",
            slug: "",
          };
        });
      },
    },
  },
  created() {
    modalBus.$on("library-save", (payload) => {
      this.images = payload.images;
      this.videos = payload.videos;
    });

    // TO IMPROVE !!!!!!
    let sell = {
      name: this.$t("properties.new_price"),
      value: "new_price",
      active: false,
    };

    let new_project = {
      name: this.$t("properties.new_project"),
      value: "new_project",
      active: false,
    };

    let opportunity = {
      name: this.$t("properties.opportunity"),
      value: "opportunity",
      active: false,
    };

    let new_property = {
      name: this.$t("properties.new_property"),
      value: "new_property",
      active: false,
    };

    this.tags = [sell, opportunity, new_property, new_project];
    // TILL HERE

    if (this.$route.params.token !== undefined) {
      this.$axios.get("/api/properties/" + this.$route.params.token).then((res) => {
        let property = res.data;
        if (res.data.property.partner) this.partner = res.data.property.partner;
        property.platforms.forEach((el) => {
          if (!this.platforms.hasOwnProperty(el.name)) this.platforms[el.name] = {};
          this.platforms[el.name][el.lang] = el.pivot.is_activated;
        });

        this.translations = this._global.availableLanguages.map((l) => {
          const index = property.translations.findIndex((t) => t.lang === l.value);
          if (index !== -1) {
            const temp = { ...property.translations[index] };
            property.translations.splice(index, 1);
            return temp;
          }

          return {
            lang: l.value,
            title: "",
            description: "",
            slug: "",
          };
        });

        this.images.current = property.images.map((el) => {
          return "" + el.url["150x150"];
        });

        this.images.ordered = this.images.current;

        let sell = {
          name: this.$t("properties.new_price"),
          value: "new_price",
          active: property.tags.includes("new_price"),
        };

        let opportunity = {
          name: this.$t("properties.opportunity"),
          value: "opportunity",
          active: property.tags.includes("opportunity"),
        };

        let new_property = {
          name: this.$t("properties.new_property"),
          value: "new_property",
          active: property.tags.includes("new_property"),
        };

        let new_project = {
          name: this.$t("properties.new_project"),
          value: "new_project",
          active: property.tags.includes("new_project"),
        };

        this.address = { ...property.address };
        this.phases = [...property.phases];
        this.videos = property.videos.map((el) => {
          return el.url;
        });

        this.form = { ...this.form, ...property.property };
        this.tags = [sell, opportunity, new_property, new_project];
      });
    }
  },
});
</script>
